<template>
  <nav>
    <div class="nav_logo">
      <img src="./assets/logos.png">
    </div>
    <div class="nav_link">
      <router-link to="/">{{ $t('index.name') }}</router-link>
      <router-link to="/brain">{{$t('brain.name')}}</router-link>
      <router-link to="/home">{{$t('home.name')}}</router-link>
      <router-link to="/ultrasonic">{{$t('ultrasonic.name')}}</router-link>
    </div>
  </nav>
  <router-view/>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1C1C1C;
  width: window-width;
  min-width: 1200px;
}
nav {
  height: 80px;
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100%;
  font-size: 18px;
  align-items: center;
  background: #FEFEFE;
  .nav_logo{
    padding-left: 23%;
    padding-right: 23%;
  }
  .nav_link{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    color: #3D3D3D;
    text-decoration: none;
    margin-left: 80px;
    &.router-link-exact-active {
      color: #010101;
      font-weight: 700;
    }
    &:last-child{
      padding-right: 100px;
    }
  }
}
</style>

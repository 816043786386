export default {
    home: {
      name: 'ecg',
    },
    brain:{
      name:'brain'
    },
    index:{
      name:'home'
    },
    ultrasonic:{
      name:'ultrasonic'
    },
    tabs: {
      work: 'Work',
      private: 'Private',
      collect: 'Collect',
      like: 'Like',
    },
  }
  